import BSModal from "bootstrap/js/dist/modal";
import { observer } from "mobx-react";
import { Fragment, h } from "preact";
import { useEffect, useState } from "preact/hooks";
import { CREATOR_MODAL_ID } from "./init";
import CreatorHeader from "./CreatorHeader";
import CreatorBody from "./CreatorBody";
import CreatorFooter from "./CreatorFooter";
import { FulfillmentSettingsTracking, t, Tracking, translate, } from "restaumatic-client";
import FulfillmentTimeModal from "../Menu/FulfillmentTimeModal";
import { hideModalBs5 } from "../../utils/modal";
function Creator(props) {
    const { productCard } = props;
    const { creator } = productCard;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const submitLabel = getSubmitLabel(creator.shouldChangeFulfillmentTime, creator.noFulfillmentTime);
    useEffect(() => {
        Tracking.trackEvent({
            tag: "VisitPage",
            contents: {
                page: { tag: "ProductCard", productIds: creator.productTypeIdValues },
            },
        });
    }, []);
    const handleSubmit = () => {
        if (!creator.isSubmitDisabled && creator.shouldChangeFulfillmentTime) {
            console.log("[Creator Submit] Invalid time. Opening time modal...", creator.fulfillmentTime.formattedValue.title);
            const creatorModalElement = document.getElementById(CREATOR_MODAL_ID);
            if (creatorModalElement) {
                hideModalBs5(creatorModalElement).then(() => {
                    setIsModalOpen(true);
                });
            }
            else {
                setIsModalOpen(true);
            }
        }
        else {
            console.log("[Creator Submit] Time ok. Adding to cart...", creator.fulfillmentTime.formattedValue.title);
            productCard.addToCart();
        }
    };
    const handleTimeSubmit = () => {
        console.log("[Time Submit] Saved. Submitting the creator...", creator.fulfillmentTime.formattedValue.title);
        setIsModalOpen(false);
        setTimeout(() => {
            creator.fulfillmentTime.save();
            FulfillmentSettingsTracking.chooseDateEvent({
                tag: "ProductCard",
                productIds: creator.productTypeIdValues,
            });
            productCard.addToCart();
        }, 200);
    };
    const handleTimeHidden = () => {
        setIsModalOpen(false);
        if (creator.notAvailableFulfillmentTimeOrEmpty) {
            console.log("[Time Hidden] Time unavailable. Opening creator again...", creator.product.name, creator.fulfillmentTime.formattedValue.title);
            setTimeout(() => {
                const creatorModal = BSModal.getOrCreateInstance(`#${CREATOR_MODAL_ID}`);
                creatorModal.show();
            }, 200);
        }
        else {
            console.log("[Time Hidden] Time available", creator.product.name, creator.fulfillmentTime.formattedValue.title);
        }
    };
    return (h(Fragment, null,
        h(CreatorHeader, { photoUrn: creator.product.photo }),
        h(CreatorBody, { creator: creator }),
        h(CreatorFooter, { price: creator.price, submitLabel: submitLabel, isSubmitVisible: creator.isSubmitVisible, isSubmitDisabled: creator.isSubmitDisabled, onSubmit: handleSubmit }),
        h(FulfillmentTimeModal, { isOpen: isModalOpen, fulfillmentTime: creator.fulfillmentTime, productCardCreator: creator, onSubmit: handleTimeSubmit, onShown: () => setIsModalOpen(true), onHidden: handleTimeHidden })));
}
function getSubmitLabel(shouldChangeFulfillmentTime, noFulfillmentTime) {
    if (shouldChangeFulfillmentTime && noFulfillmentTime) {
        return translate(t.restaurants.widgets.menu.plan_date);
    }
    if (shouldChangeFulfillmentTime && !noFulfillmentTime) {
        return translate(t.restaurants.widgets.menu.change_date);
    }
    return translate(t.theme_defaults.creator.add_to_cart);
}
export default observer(Creator);
