import { observer } from "mobx-react";
import { h } from "preact";
import CreatorChips from "./CreatorChips";
import ProductLabels from "../../components/ProductLabels";
function CreatorDetails(props) {
    const { tags, freeModifierItemsCount, productCartCreator } = props;
    return (h("div", { class: "m-product-card__details" },
        h(CreatorChips, { tags: tags, freeModifierItemsCount: freeModifierItemsCount }),
        h(ProductLabels, { labelsCreator: productCartCreator.labelsCreator })));
}
export default observer(CreatorDetails);
